import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import { Helmet } from "react-helmet"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

import fitness from "../../images/icons/Icon/Fitness.png"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Popup from "../../components/Popup"
import { SectionDivider, HalfColumn, TwoColumn } from "../../components/global"

const query = graphql`
  query {
    allStrapiStore {
      edges {
        node {
          id
          storeName
          storeLink
          storeDescription
          storeCategory
          storeImage {
            childImageSharp {
              fixed(width: 125) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

const Fitness = () => (
  <Layout>
    <Helmet>
      <title>Dili – Loans at No Cost EMI for Gym & Wellness Program Fees</title>
      <meta
        name="description"
        content="Dili no-cost EMI option covers your gym membership fees, health club membership, yoga, nutrition & other wellness program, therapies for chronic alignments & more."
      />
      <script src="/Jslibrary.js" defer></script>
    </Helmet>
    <Navigation></Navigation>
    <CategoryContainer>
      <BackLink to="/wheretoshop">← Back to all merchants</BackLink>
      <Spacing />
      <Icon src={fitness}></Icon>
      <CategoryHeader>Health and Fitness</CategoryHeader>
      <CategoryText>
        Want to join a physiotherapy regimen or the 15 day wellness trip to your
        favourite resort. Don’t postpone such essential and not so essential
        things in life. Pay through a dili loan and fulfil your dream now.
        <br />
        <br />
        Join the most happening Gym and get into peak fitness without worrying
        about how to pay for it. Take a dili loan and get on to that treadmill.
        <br />
        <br />
        <ul>
          <li>Gym and Health Club memberships</li>
          <li>Yoga and wellness programs</li>
          <li>Nutrition and special programs</li>
          <li>Alternative therapy for chronic ailments</li>
          <li>
            Physiotherapy, acupressure, acupuncture and other long term regimens
          </li>
        </ul>
      </CategoryText>

      <Spacing />
      <Stores>
        <StaticQuery
          query={query}
          render={(data) => (
            <StoreC>
              {data.allStrapiStore.edges
                .filter(
                  (store) => store.node.storeCategory === "healthandfitness"
                )
                .map((store) => (
                  <Store>
                    <ImgContainer>
                      <StoreImg
                        fixed={store.node.storeImage.childImageSharp.fixed}
                      />
                    </ImgContainer>
                    <StoreHeader id={"StoreHeader_" + store.node.id}>
                      {store.node.storeName}
                    </StoreHeader>
                    <StoreTextContainer>
                      <StoreText id={"StoreText_" + store.node.id}>
                        {store.node.storeDescription}
                      </StoreText>
                      <ReadMoreText>
                        <Link
                          to={
                            "javascript:ShowPopup(" +
                            "StoreHeader_" +
                            store.node.id +
                            ",StoreText_" +
                            store.node.id +
                            ");"
                          }
                        >
                          Read More...
                        </Link>
                      </ReadMoreText>
                    </StoreTextContainer>
                    {/* <StoreButtonContainer> */}
                    <Link to={store.node.storeLink}>
                      <StoreButton>Shop now</StoreButton>
                    </Link>
                    {/* </StoreButtonContainer> */}
                  </Store>
                ))}
            </StoreC>
          )}
        />
      </Stores>
      <Popup></Popup>
      <Spacing />
      <FormText>
        <FormHeader>
          Don’t see what you want to buy here? Help us curate a loan option for you.
        </FormHeader>
        <FormSubheader>
          At dili, we are constantly looking to serve our customers better. If
          your requirement does not fit in the categories listed above, or the
          merchant you wish to buy from is not currently signed up with dili,
          tell us about it and we will try to curate a loan option for you.
        </FormSubheader>
      </FormText>
      <FormContainer>
        <FormEmbed
          src="https://docs.google.com/forms/d/e/1FAIpQLScbyaRY2G20xafoCvqNfw_37ZlsT9-bR63qV-LoW__eiKKkig/viewform?embedded=true"
          width="640"
          height="640"
          frameBorder="0"
        >
          Loading...
        </FormEmbed>
      </FormContainer>
    </CategoryContainer>
    <Footer />
  </Layout>
)

export default Fitness

export const Spacing = styled.div`
  margin-bottom: 50px;
`

export const CategoryContainer = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;
  border: 0px solid blue;
`

export const CategoryHeader = styled.div`
  color: #142630;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin-right: 40px;
`

export const CategoryText = styled.div`
  color: #465f6d;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-top: 20px;
  width: 100%;
  text-align: justify;
`

export const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 32px;
`

export const Stores = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 0px solid blue;
  justify-content: center;
`

export const Store = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
  margin-bottom: 24px;
  border: 2px solid white;
`

export const ImgContainer = styled.div`
  height: 140px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  position: relative;
  background-color: #f7f7f7;
  box-shadow: 2px 2px 2px 2px #d9d9d9;

  &:hover {
    transform: scale(1.1);
    // z-index: 1000;
    border: 1px solid #c6c6c6;
    box-shadow: 2px 2px 2px 2px #8b8b8b;
  }
`

export const StoreImg = styled(Img)`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export const StoreHeader = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 8px;
  font-weight: bold;
  width: 240px;
  // border:1px solid red;
`
export const StoreTextContainer = styled.div`
  width: 240px;
  height: 65px;
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;
  background-color: #ffffff;
`

export const StoreText = styled.div`
  font-size: 15px;
  color: grey;
  padding: 5px;
  width: 100%;
  height: 70%;
  line-height: 1.3;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: justify;
`

export const ReadMoreText = styled.div`
  font-size: 13px;
  width: 100%;
  padding-right: 5px;
  text-align: right;
`
export const StoreButtonContainer = styled.div`
  width: 96px;
`

export const StoreButton = styled.button`
  background: #505050;
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 96px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 40px;
`

const FormText = styled.div`
  flex-direction: column;
  margin-top: 0px;
`

const FormHeader = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #142630;
  width: 60%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 40px;
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    font-size: 24px;
    width: 80%;
  }
`

const FormSubheader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  text-align: justify;
  font-size: 20px;
  color: #465f6d;
  line-height: 1.5;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 480px;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    width: 360px;
    font-size: 14px;
  }
`

const FormContainer = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
  width: 640px;
  height: 640px;
`

export const StoreC = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid green;
  justify-content: justify;
`
export const BackLink = styled(Link)`
  margin-bottom: 80px;
`
